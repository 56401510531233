<swe-pageheader [header]="languageService.getItem(1312)">
</swe-pageheader>
<div class="row d-block py-2">
  <!--Settings-->
  <div class="col-12 col-md-4 float-start">
    <swe-economysettings (settingsChanged)="settingChanged($event)"></swe-economysettings>
  </div>
  <!--Statistics-->
  <div class="col-12 col-md-8 float-start">
    <swe-economystatistics></swe-economystatistics>
  </div>
  <div class="col-12 col-md-8 float-start">
    <swe-economyorders [settings]="settings"></swe-economyorders>
  </div>
</div>
