import { Component, Input, OnInit } from "@angular/core";
import { LanguageService } from "../../../_services/language.service";
import { DataService } from "../../../_services/data.service";
import { AlertService } from "../../../_services/alert.service";
import { PermissionService } from "../../../_services/permission.service";
import { settings } from "cluster";
import { DateTimeService } from "../../../_services/datetime.service";

@Component({
  selector: 'swe-economyorders',
  templateUrl: './economyorders.component.html'
})
export class EconomyOrdersComponent implements OnInit {

  constructor(public datetimeService: DateTimeService,
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this._units = [{ Id: 0, Name: this.languageService.getItem(724) }, { Id: 1, Name: this.languageService.getItem(1524) }]
    this._quotationStatusList = [
      { Id: 0, Name: "" },
      { Id: 1, Name: this.languageService.getItem(1534) },
      { Id: 2, Name: this.languageService.getItem(1535) },
      { Id: 3, Name: this.languageService.getItem(1536) },
      { Id: 4, Name: this.languageService.getItem(1537) },
      { Id: 5, Name: this.languageService.getItem(1538) },
      { Id: 6, Name: this.languageService.getItem(1539) },
    ]
    this.search();
  }

  @Input() settings: any;
  private _top: number = 25;
  private _multiple: number = 1;
  private _editing: boolean = false;
  private _rows: any[] = [];
  private _selectedRow: any;
  private _units: any[];
  private _quotationStatusList: any[];
  private _loading: boolean = false;
  private _more: boolean = false;

  //Properties
  public get units() {
    return this._units;
  }
  public get more() {
    return this._more;
  }
  public get quotationStatusList() {
    return this._quotationStatusList;
  }
  public get selectedRow() {
    return this._selectedRow;
  }
  public set selectedRow(val) {
    this._selectedRow = val;
  }
  public get rows() {
    return this._rows;
  }
  public get editing() {
    return this._editing;
  }
  public set editing(value) {
    this._editing = value;
  }


  //Methods
  public getQuotationStatus(status) {
    const statusObj = this._quotationStatusList.find(x => x.Id == status);
    if (!statusObj) {
      return '';
    }
    return statusObj.Name;
  }
  public getUnit(unit) {
    const unitObj = this._units.find(x => x.Id == unit);
    if (!unitObj) {
      return '';
    }
    return unitObj.Name;
  }

  public editRow(row) {
    if (!this.permissionService.user.IsSuper) {
      return;
    }
    this._selectedRow = row;
    this._editing = true;
  }

  public close() {
    this._editing = false;
    this._selectedRow = this.defaultRow();
  }

  public search(getMore: boolean = false, getall: boolean = false) {
    if (this._loading == true) {
      return;
    }
    this._loading = true;
    this._more = false;

    if (getMore) {
      this._multiple = 1 + (this._multiple / 4);
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._rows = [];
    }
    if (getall) {
      this._top = 0;
    }

    const filter = {
      Top: this._top,
      Multiple: this._multiple,
    };
    this.dataService.tokenRequest('/api/v1/economy/orders/search', 'POST', filter).subscribe(res => {
      if (res) {
        if (this._rows.length == 0) {
          this._rows = res.Orders;
        } else {
          res.Orders.forEach((order) => {
            this._rows.push(order);
          });
        }
        this._more = res.More;
        this._loading = false;
      }
    });
  }
  public save() {
    if (!this.permissionService.user.IsSuper) {
      return;
    }
    if (!this.validateRow()) {
      return;
    }
    this.dataService.tokenRequest('/api/v1/economy/orders', 'PUT', this._selectedRow, 'text', 'response').subscribe(res => {
      if (res) {
        this.alertService.Add({ message: res.body, type: 'success' });
        if (this._selectedRow.OrderId == 0) {
          this.close();
        }
        this.search();
      }
    });
  }

  public delete(id) {
    if (!this.permissionService.user.IsSuper) {
      return;
    }
    this.dataService.tokenRequest('/api/v1/economy/orders/' + id, 'DELETE', 'text', 'response').subscribe(res => {
      if (res) {
        this.alertService.Add({ message: res.body, type: 'success' });
        this.close();

        this.search();
      }
    });
  }

  public add(event) {
    event.stopPropagation();
    if (!this.permissionService.user.IsSuper) {
      return;
    }
    this._selectedRow = this.defaultRow();
    this._editing = true;
  }

  //Functions
  private defaultRow() {
    return { OrderId: 0, Account: 0, Description: '', Amount: 0, Unit: 0, Price: this.settings.ConsultantFee, Orderer: '', DateOrdered: new Date(), DateDelivered: null, QuotationStatus: 0 };
  }

  private validateRow() {
    if (this._selectedRow.Account == null) {
      this.alertService.Add({ message: this.languageService.getItem(66).replace('{0}', this.languageService.getItem(1532)), type: 'warning' });
      return false;
    }
    if (!this._selectedRow.Description && this._selectedRow.Description == '') {
      this.alertService.Add({ message: this.languageService.getItem(66).replace('{0}', this.languageService.getItem(1520)), type: 'warning' });
      return false;
    }
    if (this._selectedRow.Amount == null) {
      this._selectedRow.Amount = 0;
    }
    if (this._selectedRow.Amount == null) {
      this._selectedRow.Unit = 0;
    }
    if (this._selectedRow.Amount == null) {
      this._selectedRow.Price = 0;
    }
    if (!this._selectedRow.Orderer && this._selectedRow.Orderer == '') {
      this.alertService.Add({ message: this.languageService.getItem(66).replace('{0}', this.languageService.getItem(1522)), type: 'warning' });
      return false;
    }
    if (!this._selectedRow.DateOrdered) {
      this.alertService.Add({ message: this.languageService.getItem(66).replace('{0}', this.languageService.getItem(1523)), type: 'warning' });
      return false;
    }
    return true;

  }

}
