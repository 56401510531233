<swe-card [header]="languageService.getItem(1526)" (refreshChange)="search()">
  <button *ngIf="permissionService.user.IsSuper" swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded mb-2" (click)="add($event)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
  <div class="row">
    <!--Edit view-->
    <div *ngIf="editing&&permissionService.user.IsSuper" class="col-xl-6 col-xxl-4">
      <swe-card [header]="languageService.getItem(1525) + ' (#'+ selectedRow.OrderId +')'" [hasRefresh]="false" [hasCollapse]="false" [hasSave]="true" (saveChange)="save()" [hasClose]="true" (closeChange)="close()">
        <swe-element type="System.Int32" [label]="languageService.getItem(1532)" [statusLabel]="2" [(model)]="selectedRow.Account"></swe-element>
        <swe-element type="System.Text" [statusLabel]="2" [label]="languageService.getItem(1520)" [(model)]="selectedRow.Description"></swe-element>
        <swe-element type="System.Text" [statusLabel]="2" [label]="languageService.getItem(1522)" [(model)]="selectedRow.Orderer"></swe-element>
        <div class="row">
          <div class="col-xxl-3">
            <swe-element type="System.Int32" [statusLabel]="2" [label]="languageService.getItem(794)" [(model)]="selectedRow.Amount"></swe-element>
          </div>
          <div class="col-xxl-4">
            <swe-element type="System.List" [statusLabel]="2" [items]="units" [label]="languageService.getItem(1521)" [(model)]="selectedRow.Unit"></swe-element>
          </div>
          <div class="col-xxl-5">
            <swe-element type="System.Int32" [statusLabel]="2" [label]="languageService.getItem(1542)" [(model)]="selectedRow.Price"></swe-element>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <swe-element type="System.Date" [statusLabel]="2" [label]="languageService.getItem(1523)" [(model)]="selectedRow.DateOrdered"></swe-element>
          </div>
          <div class="col-6">
            <swe-element type="System.Date" [statusLabel]="2" [label]="languageService.getItem(1540)" [(model)]="selectedRow.DateDelivered"></swe-element>
          </div>
        </div>
        <swe-element type="System.List" [items]="quotationStatusList" [statusLabel]="2" [label]="languageService.getItem(1541)" [(model)]="selectedRow.QuotationStatus"></swe-element>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="save()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
          <swe-confirm *ngIf="selectedRow.OrderId>0" [body]="languageService.getItem(1527)" (acceptChange)="delete(selectedRow.OrderId)">
            <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
          </swe-confirm>
        </div>
      </swe-card>
    </div>
    <!--Table view-->
    <div class="table-responsive" [ngClass]="{'col-md-12': !editing, 'col-xxl-8 col-xl-6': editing}">
      <div class="list-group-item" *ngIf="rows && rows.length == 0">
        <span class="fst-italic">{{languageService.getItem(996)}}</span>
      </div>
      <table class="table" *ngIf="rows && rows.length > 0">
        <thead>
          <tr>
            <th [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{languageService.getItem(1532)}}</th>
            <th>{{languageService.getItem(1520)}}</th>
            <th [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{languageService.getItem(794)}}</th>
            <th [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{languageService.getItem(1245)}}</th>
            <th>{{languageService.getItem(1522)}}</th>
            <th>{{languageService.getItem(1523)}}</th>
            <th>{{languageService.getItem(1540)}}</th>
            <th [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{languageService.getItem(1541)}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows" (click)="editRow(row)" [ngClass]="{'bg-info bg-opacity-25': selectedRow && selectedRow.OrderId == row.OrderId, 'swe-click': permissionService.user.IsSuper}">
            <td [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{row.Account}}</td>
            <td>{{row.Description}}</td>
            <td [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{row.Amount}}&nbsp;{{getUnit(row.Unit)}}</td>
            <td [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{row.Price * row.Amount}}</td>
            <td>{{row.Orderer}}</td>
            <td>{{row.DateOrdered ? datetimeService.format(row.DateOrdered, 'yyyy-MM-dd') : ''}}</td>
            <td>{{row.DateDelivered ? datetimeService.format(row.DateDelivered, 'yyyy-MM-dd') : ''}}</td>
            <td [ngClass]="{'d-xl-none d-xxl-table-cell': editing}">{{getQuotationStatus(row.QuotationStatus)}}</td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </div>
    </div>
  </div>
</swe-card>
