<div class="position-fixed bottom-0 my-4 end-0 mx-4" style="z-index:999" *ngIf="permissionService.permissions&&permissionService.permissions.HasGuides">
<div class="position-absolute" style="right:25px;bottom:25px;min-width:200px; z-index:-1;" *ngIf="openMenu">
  <div class="list-group bg-white">
    <a class="list-group-item list-group-item-action swe-click bg-info bg-opacity-25 fw-bolder" href="/help"><i class="bi bi-swe-fw bi-question-lg"></i>&nbsp;{{languageService.getItem(742)}}</a>
    <a class="list-group-item list-group-item-action swe-click" *ngFor="let guide of guides" (click)="openGuide(guide)">{{guide.Name}}</a>
    <button (click)="loadGuides(true)" class="btn btn-primary" *ngIf="more">{{languageService.getItem(57)}}</button>
    <input type="text" class="form-control list-group-item" [(ngModel)]="search" (ngModelChange)="manageSearch()" [placeholder]="languageService.getItem(13) + '...'"/>
  </div>
</div>
  <div class="bottom-0 end-0">
    <button class="btn btn-primary rounded-circle text-center d-flex align-items-center justify-content-center"  (click)="openMenu=!openMenu;loadGuides()" style="width:50px;height:50px;"><i class="bi bi-info-circle bi-swe-2x"></i></button>
  </div>
</div>

<div *ngIf="selected&&permissionService.permissions&&permissionService.permissions.HasGuides" class="position-fixed top-50 start-50 translate-middle swe-popup" >
  <swe-guidepreview [id]="selected.Id" [isBaseGuide]="selected.IsBaseGuide" [hasRefresh]="false" [hasClose]="true" (closed)="close()" [hasEdit]="permissionService.permissions.GuideAccess>1" [title]="selected.Name" [alwaysOpen]="true"></swe-guidepreview>
</div>
<div class="swe-element-clickable-area swe-element-clickable-area-md" *ngIf="openMenu" (click)="openMenu=false" style="z-index:998"></div>
<div *ngIf="selected" class="swe-element-clickable-area swe-element-clickable-area-md bg-black bg-opacity-50" (click)="close()">
</div>
