<swe-popup [header]="languageService.getItem(984)" [doneText]="buttontext"
           [(visible)]="visible" [event]="option" (visibleChange)="manageVisibility($event)"
           (doneChange)="saveAbscent()" [manualClose]="true">
  <div swe-popup-body>
    <!--Level-->
    <div class="mb-3 row" *ngIf="!loading&&!permissionService.permissions.HideLevels&&bookingid==0&&timereport">
      <div class="swe-row-icon">
        <div class="swe-col-icon">
          <i class="bi bi-swe-fw bi-swe bi-diagram-3-fill" [attr.title]="languageService.getItem(14)"></i>
        </div>
        <div class="swe-col-content">
          <swe-levelsearch [(model)]="timereport.LevelId" [displayname]="timereport.LevelName" [onlyone]="true" [type]="'Booking'" [disabled]="timereport.LevelAccess<2" [markChanges]="true" [storeAtClient]="true"></swe-levelsearch>
          <span class="small" *ngIf="timereport.LevelPath&&timereport.LevelPath.length>0"><i>({{timereport.LevelPath}})</i></span>
        </div>
      </div>
    </div>
    <!--DateTimes-->
    <div class="mb-3 row" *ngIf="!loading&&bookingid==0&&timereport">
      <div class="swe-row-icon">
        <div class="swe-col-icon">
          <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-clock-fill': !abscent, 'bi-swe-bookingtype-abscent': abscent}" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
        </div>
        <div class="swe-col-content">
          <swe-datetime [(start)]="timereport.Start" [(end)]="timereport.End" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideTime]="!permissionService.permissions.ShiftTime" [disabled]="timereport.StartAccess<2" (startChange)="manageDateTime($event)" (endChange)="manageDateTime($event)" [markChanges]="true" [undoChanges]="isReload"></swe-datetime>
        </div>
      </div>
    </div>
    <!--TimeType-->
    <div class="row" *ngIf="!loading&&timereport&&timetypes.length>0">
      <div class="swe-row-icon">
        <div class="swe-col-icon">
          <i class="bi bi-swe-fw bi-swe bi-journal" [attr.title]="languageService.getItem(1121)"></i>
        </div>
        <div class="swe-col-content">
          <swe-element [(model)]="timereport.TimeType" (modelChange)="relatedObjectChange()" [label]="languageService.getItem(1115)" [type]="'System.List'" [items]="timetypes"></swe-element>
        </div>
      </div>
    </div>
    <!--Profile-->
    <div *ngIf="!loading">
      <swe-profile [statusLabel]="2" [(model)]="timereport.Profile" (modelChange)="saveAbscent()" [id]="id" *ngIf="timereport" [relatedObjectList]="relatedObjectList"></swe-profile>
    </div>
  </div>
  <div class="d-flex mt-2 mb-0 me-3" *ngIf="permissionService.permissions.CreateSubstitute" footer-extra>
    <span class="mt-1 me-2">{{languageService.getItem(1304)}}</span>
    <swe-element [label]="languageService.getItem(1304)" type="System.Boolean" [(model)]="substitute"></swe-element>
  </div>
</swe-popup>
