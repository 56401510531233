<swe-pageheader [header]="languageService.getItem(1408)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2">
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(1483)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.permissions.Templates>0">
      <swe-element [type]="'System.Text'" [label]="languageService.getItem(509)" [statusLabel]="2" [(model)]="template.Name"></swe-element>
      <div class="mb-3">
        <swe-element [bottomMargin]="0" [type]="'System.LevelSearch'" [label]="languageService.getItem(14)" [statusLabel]="2" [(model)]="template.LevelId" [display]="template.LevelName" [optional]="true"></swe-element>
        <span class="small" *ngIf="template.LevelPath&&template.LevelPath.length>0"><i>({{template.LevelPath}})</i></span>
      </div>
      <swe-element *ngIf="template.Type == 2" [type]="'System.List'" [label]="languageService.getItem(15)" [statusLabel]="2" [(model)]="template.Status" [items]="generalService.bookingstatus" [optional]="true" [optionalValue]="0"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(1081)" [statusLabel]="2" [(model)]="template.Type" [items]="types"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(1423)" [statusLabel]="2" [(model)]="template.WeekOne" [items]="weekOneTypes"></swe-element>
      <div class="mb-3" *ngIf="template.CreatedFromId>0">
        <label class="col-form-label">Skapad från behovsmall</label>
        <div>
          <a href="javascript:void(0);" class="swe-click" (click)="goto(template.CreatedFromId,$event)">
            {{template.CreatedFromName}}
          </a>
          <span class="ms-2">{{template.CreatedFromPercentage ? (template.CreatedFromPercentage + ' %') : '(Manuellt justerad)' }}</span>
        </div>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="(permissionService.permissions.Templates>1)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-primary" (click)="copy()" *ngIf="id>0&&(permissionService.permissions.Templates>1)"><i class="bi bi-journals"></i>&nbsp;{{languageService.getItem(477)}}</button>
        <button class="btn btn-success" (click)="connect()" *ngIf="id>0&&(permissionService.permissions.Templates>1)"><i class="bi bi-link"></i>&nbsp;{{languageService.getItem(1379)}}</button>
        <swe-confirm [body]="getConfirmBodyText()" (acceptChange)="delete()" *ngIf="id>0&&(permissionService.permissions.Templates>2)">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-8">
    <swe-card [header]="languageService.getItem(492)" [color]="'swe'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
      <div class="row py-2">
        <div class="col-12 col-md-6">
          <swe-element [type]="'System.Date'" [label]="languageService.getItem(396)" [statusLabel]="2" [(model)]="template.Start"></swe-element>
          <swe-element [type]="'System.Date'" [label]="languageService.getItem(397)" [statusLabel]="2" [(model)]="template.End"></swe-element>
          <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(988)" [statusLabel]="2" [(model)]="template.ShowOverview"></swe-element>
          <swe-element [type]="'System.Boolean'" [disabled]="permissionService.permissions.EmploymentPlan < 2" [label]="languageService.getItem(698)" [statusLabel]="2" [(model)]="template.EmploymentPlan" *ngIf="permissionService.permissions.EmploymentPlan > 0 && template.Type == 2"></swe-element>
          <swe-element [type]="'System.List'" [label]="languageService.getItem(1378)" [statusLabel]="2" [(model)]="template.ParentId" [items]="parents" [optional]="true" [optionalValue]="0" *ngIf="template.Type == 2"></swe-element>
        </div>
        <div class="col-12 col-md-6">
          <swe-element [type]="'System.List'" [label]="languageService.getItem(1478)" [statusLabel]="2" [(model)]="template.SchedulingOption" [items]="schedulingoptions" *ngIf="template.Type == 2"></swe-element>
          <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(1482)" [statusLabel]="2" [(model)]="template.SchedulingRepeat" *ngIf="template.Type == 2"></swe-element>
        </div>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="(permissionService.permissions.Templates>1)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
    <swe-card [header]="'Konvertering (passegenskaper)'" [color]="'swe'" [open]="false" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="load(true)" [hasSave]="false" *ngIf="id>0&&template.Type==1">
      <div class="mb-3">
        <label class="col-form-label">Maximal tid</label>
        <div>
          <input type="number" class="form-control" style="display: inline-block;width:60px;" min="0" step="1" [(ngModel)]="optimizeMaxTimeMinutes" />
          min
          <input type="number" class="form-control" style="display: inline-block; width: 60px;" min="0" max="59" step="1" [(ngModel)]="optimizeMaxTimeSeconds" />
          sek
        </div>
      </div>
      <swe-element [type]="'System.List'" [label]="'Utgå från tidigare schemamall'" [statusLabel]="2" [(model)]="optimizeParentId" [items]="parents" [optional]="true" [optionalValue]="0"></swe-element>
      <div class="mb-3">
        <label class="col-form-label">Passlängd i minuter (min, max och noggrannhet)</label>
        <div>
          <input type="number" class="form-control" style="display: inline-block;width:65px;" min="0" step="1" [(ngModel)]="optimizeShiftMin" />
          -
          <input type="number" class="form-control" style="display: inline-block; width: 65px;" min="0" step="1" [(ngModel)]="optimizeShiftMax" />
          <select class="form-control form-select ms-4" style="display: inline-block;width:120px;" [(ngModel)]="optimizeShiftPrecision">
            <ng-container *ngFor="let precision of optimizeShiftPrecisions">
              <option [value]="precision.Id">{{precision.Name}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label class="col-form-label">Föredragen passlängd i minuter (samt exponentiell felfaktor)</label>
        <div>
          <input type="number" class="form-control" style="display: inline-block;width:65px;" min="0" step="1" [(ngModel)]="optimizeShiftPrefered" [disabled]="optimizeShiftPreferedFactor==1" />
          min
          <input type="number" class="form-control ms-5" style="display: inline-block; width: 65px;" min="1" step="1" [(ngModel)]="optimizeShiftPreferedFactor" />
        </div>
      </div>
      <swe-card [header]="'Rast'" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
        <div class="row py-2">
          <div class="col-12 col-md-4">
            <swe-element [type]="'System.Int32'" [label]="'Rast skapas om passet är längre än (minuter)'" [statusLabel]="2" [(model)]="optimizeShiftLengthWhenCreateBreak"></swe-element>
            <div class="mb-3">
              <label class="col-form-label">Rastlängd i minuter (min, max och noggrannhet)</label>
              <div>
                <input type="number" class="form-control" style="display: inline-block;width:65px;" min="0" step="1" [(ngModel)]="optimizeBreakMin" [disabled]="optimizeMealBreak" />
                -
                <input type="number" class="form-control" style="display: inline-block; width: 65px;" min="0" step="1" [(ngModel)]="optimizeBreakMax" [disabled]="optimizeMealBreak" />
                <select class="form-control form-select ms-4" style="display: inline-block;width:120px;" [(ngModel)]="optimizeBreakPrecision" [disabled]="optimizeMealBreak">
                  <ng-container *ngFor="let precision of optimizeShiftPrecisions">
                    <option [value]="precision.Id">{{precision.Name}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <swe-element [type]="'System.Boolean'" [label]="'Rasten ska bemannas av annan person'" [statusLabel]="2" [(model)]="optimizeBreakNeedToBeStaffed" [disabled]="optimizeMealBreak"></swe-element>
            <swe-element [type]="'System.Boolean'" [label]="'Använd måltidsuppehåll istället för rast'" [statusLabel]="2" [(model)]="optimizeMealBreak"></swe-element>
          </div>
        </div>
      </swe-card>
      <swe-element [type]="'System.Int32'" [label]="'Felfaktor överbemanning'" [statusLabel]="2" [(model)]="optimizeFactorOverTime"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="'Felfaktor underbemanning'" [statusLabel]="2" [(model)]="optimizeFactorUnderTime"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-success" (click)="convertToSchedule()" [disabled]="template.IsRunning"><i class="bi bi-calendar3"></i>&nbsp;Konvertera till schemamall</button>
      </div>
      <div class="row py-2" *ngIf="template.CreatedFrom.length>0">
        <div class="col-12">
          <swe-list [data]="template.CreatedFrom" [loading]="isloading">
            <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let createdFrom of template.CreatedFrom" (click)="goto(createdFrom.Key,$event)">
              <div class="col-12 col-md-6">{{createdFrom.Value}}</div>
              <div class="col-12 col-md-6">{{(createdFrom.Extra && createdFrom.Extra.length>0) ? (createdFrom.Extra + ' %') : '(Manuellt justerad)' }}</div>
            </a>
          </swe-list>
        </div>
      </div>
    </swe-card>
      <swe-card [header]="languageService.getItem(1373)" [color]="'swe'" [open]="false" [hasCollapse]="true" [hasRefresh]="false" [hasSave]="false" *ngIf="id>0&&template.Type==2">
        <div class="row py-2">
          <div class="col-12 col-md-4">
            <swe-element [type]="'System.List'" [label]="languageService.getItem(1374)" [statusLabel]="2" [(model)]="template.CalcDayStart" [items]="calcweekdays" [optional]="true" [optionalValue]="-1"></swe-element>
            <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1376)" [statusLabel]="2" [(model)]="template.CalcNoDays"></swe-element>
            <swe-element [type]="'System.List'" [label]="languageService.getItem(1377)" [statusLabel]="2" [(model)]="template.MidnightOption" [items]="midnightoptions" [optional]="true" [optionalValue]="0"></swe-element>
            <swe-element [type]="'System.Double'" [label]="languageService.getItem(1386)" [statusLabel]="2" [(model)]="template.FullTime"></swe-element>
            <swe-element [type]="'System.Double'" [label]="languageService.getItem(1387)" [statusLabel]="2" [(model)]="template.Admin"></swe-element>
          </div>
          <div class="col-12 col-md-4">
            <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1382)" [statusLabel]="2" [(model)]="template.NumberOfShifts" [disabled]="true"></swe-element>
            <swe-element [type]="'System.Double'" [label]="languageService.getItem(1286)" [statusLabel]="2" [model]="template.NumberOfHours" [disabled]="true"></swe-element>
            <div class="float-end">
              <a href="javascript:void(0)" class="swe-no-link me-1" (click)="prevYear()"><i class="bi bi-swe-fw bi-dash-circle"></i></a>
              <span>{{languageService.getItem(694)}} {{calculationYear}}</span>
              <a href="javascript:void(0)" class="swe-no-link ms-1" (click)="nextYear()"><i class="bi bi-swe-fw bi-plus-circle"></i></a>
            </div>
            <swe-element [type]="'System.Double'" [label]="languageService.getItem(1422)" [statusLabel]="2" [(model)]="calculationYearHours" [disabled]="true"></swe-element>
            <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1383)" [statusLabel]="2" [(model)]="template.OffDuty" [disabled]="true"></swe-element>
            <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1384)" [statusLabel]="2" [(model)]="template.Vacation" [disabled]="true"></swe-element>
            <swe-element [type]="'System.Double'" [label]="languageService.getItem(1388)" [statusLabel]="2" [(model)]="template.EmploymentRate" [disabled]="true"></swe-element>
          </div>
          <div class="col-12 col-md-4">
            <swe-element [type]="'System.Double'" [label]="getTimetypeActivityLabel(item)" [statusLabel]="2" [model]="item.Hours" [disabled]="true" *ngFor="let item of template.TimeTypeFactors"></swe-element>
          </div>
          <div class="d-grid gap-2">
            <button class="btn btn-primary" (click)="save()" *ngIf="(permissionService.permissions.Templates>1)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
          </div>
        </div>
      </swe-card>
</div>
  <div class="col-12">
    <swe-card [header]="languageService.getItem(6)" [hasRefresh]="false" [open]="true" *ngIf="template && template.Settings">
      <span swe-rightcommand class="bi-swe-pull-right bg-success rounded text-white">
        <i class="bi bi-swe-fw bi-plus-lg" (click)="addSetting($event)"></i>
      </span>
      <div class="table-responsive mt-3">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>{{languageService.getItem(480)}}</th>
              <th>{{languageService.getItem(14)}}</th>
              <th *ngIf="template.Type==2">{{languageService.getItem(15)}}</th>
              <th>{{languageService.getItem(899)}}</th>
              <th *ngIf="permissionService.permissions.HasBreaks&&template.Type==2">{{languageService.getItem(785)}}</th>
              <th>{{languageService.getItem(1012)}}</th>
              <th>{{languageService.getItem(1013)}}</th>
              <th>{{languageService.getItem(355)}}</th>
              <th>{{languageService.getItem(1014)}}</th>
              <th *ngIf="template.Type==1">{{languageService.getItem(1015)}}</th>
              <th *ngIf="template.Type==1">{{languageService.getItem(1016)}}</th>
              <th>{{languageService.getItem(1017)}}</th>
              <th *ngIf="template.Type==1">{{languageService.getItem(1018)}}</th>
              <th *ngIf="template.Type==2">{{languageService.getItem(395)}}</th>
              <th>{{languageService.getItem(156)}}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let setting of template.Settings; index as i;">
              <tr [ngClass]="{'bg-danger text-light': setting.IsDeleted && !setting.IsEdit, 'bg-warning': setting.IsError && !setting.IsEdit,'bg-success text-light': setting.IsNew && !setting.IsEdit, 'swe-disabled-block': !setting.IsActive, 'bg-info': setting.IsEdit}">
                <td style="width:150px;" class="pt-2"><span class="mt-3">{{setting.Name}}</span></td>
                <td class="pt-2">
                  <span>{{setting.CurrentLevelName}} <span class="small" *ngIf="setting.CurrentLevelPath&&setting.CurrentLevelPath.length>0"><i>({{setting.CurrentLevelPath}})</i></span> </span>
                </td>
                <td class="pt-2" *ngIf="template.Type==2"><span class="mt-3">{{setting.CurrentStatusName}}</span></td>
                <td class="pt-2"><span style="white-space:nowrap;">{{getTimeSpan(setting.Start, setting.End)}}</span></td>
                <td class="pt-2" *ngIf="permissionService.permissions.HasBreaks&&template.Type==2">
                  <span *ngIf="setting.MealBreak">{{languageService.getItem(1415)}}</span>
                  <ng-container *ngIf="!setting.MealBreak">
                    <div *ngFor="let break of setting.Breaks;">
                      <span class="text-nowrap" style="white-space:nowrap;" *ngIf="break.Break > 0">{{getTime(break.BreakStart)}} ({{break.Break}} {{languageService.getItem(786).substring(0,3)}})</span>
                    </div>
                  </ng-container>
                </td>
                <td>
                  <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(1012)" [statusLabel]="0" [(model)]="setting.Weekdays" [items]="weekdays" [container]="{isbit:true}" [access]="1"></swe-element>
                  <span *ngIf="setting.ExcludeHolidays">{{languageService.getItem(1468)}}</span>
                </td>
                <td class="pt-2"><span>{{setting.WeekNr}}</span></td>
                <td class="pt-2"><span>{{setting.IterationName}}</span></td>
                <td class="pt-2"><span>{{setting.Quantity}}</span></td>
                <td class="pt-2" *ngIf="template.Type==1"><span>{{setting.Min}}</span></td>
                <td class="pt-2" *ngIf="template.Type==1"><span> {{setting.Max}}</span></td>
                <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1017)" [statusLabel]="0" [model]="setting.TimeType" [items]="timetypes" [optional]="true" [access]="1"></swe-element></td>
                <td class="pt-2" *ngIf="template.Type==1"><span>{{setting.ActivityName}}</span></td>
                <td class="pt-2" *ngIf="template.Type==2">
                  <div *ngFor="let activity of setting.Activities" class="px-2" [ngClass]="{'bg-danger text-light': activity.IsDeleted}">
                    <span *ngIf="activity.Start && activity.End" style="white-space:nowrap;">{{getActivityName(activity.ActivityType)}} {{getTimeSpan(activity.Start, activity.End)}}</span>
                    <span *ngIf="!activity.Start || !activity.End" style="white-space:nowrap;">{{getActivityName(activity.ActivityType)}}</span>
                  </div>
                </td>
                <td>
                  <div *ngFor="let bookingprofile of setting.Properties" class="flex-row d-flex px-2" [ngClass]="{'bg-danger text-light': bookingprofile.IsDeleted}">
                    <swe-element [type]="'System.List'" [label]="languageService.getItem(1020)" [statusLabel]="0" [model]="bookingprofile.PropertyId" [items]="properties" [access]="1"></swe-element>
                    <swe-element [model]="bookingprofile.Value" [label]="languageService.getItem(1021)" [type]="bookingprofile.Type" [items]="manageReload(bookingprofile)" [access]="1"></swe-element>
                  </div>
                </td>
                <td>
                  <a href="javascript:void(0)" class="swe-no-link me-2" (click)="editSetting(setting,i)" [attr.title]="!setting.IsEdit ? languageService.getItem(1299):languageService.getItem(1301)"><i class="bi bi-swe-fw bi-swe bi-swe" [ngClass]="{'bi-pencil-square': !setting.IsEdit, 'bi-x-lg': setting.IsEdit}"></i></a>
                  <a href="javascript:void(0)" class="swe-no-link me-2" (click)="addSetting($event, setting)" [attr.title]="languageService.getItem(1298)" *ngIf="!setting.IsEdit"><i class="bi bi-swe-fw bi-swe bi-copy"></i></a>
                  <a href="javascript:void(0)" class="swe-no-link" (click)="deleteSetting(setting)" [attr.title]="languageService.getItem(1300)" *ngIf="!setting.IsEdit"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-trash-fill': !setting.IsDeleted, 'bi-arrow-counterclockwise': setting.IsDeleted}"></i></a>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="15"><button class="btn btn-success" (click)="addSetting($event)"><i class="bi bi-swe-fw bi-plus-lg"></i>{{languageService.getItem(100)}}</button></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="text-md-center mb-4" *ngIf="selectedSetting">
        <div class="btn-group">
          <button class="btn btn-swe" [disabled]="selectedIndex <=0" (click)="page($event, -1)"><i class="bi bi-swe-fw bi-swe bi-arrow-left"></i></button>
          <span class="my-2 mx-3">{{selectedIndex}}</span>
          <button class="btn btn-swe" [disabled]="selectedIndex >= template.Settings.length -1" (click)="page($event, 1)"><i class="bi bi-swe bi-swe-fw bi-arrow-right"></i></button>
          <button class="btn btn-swe bg-success ms-3 text-white" (click)="addSetting($event)" [attr.title]="languageService.getItem(100)"><i class="bi bi-swe-fw bi-swe bi-plus-lg"></i></button>
          <button class="btn btn-swe btn-primary" (click)="addSetting($event, selectedSetting)" [attr.title]="languageService.getItem(1298)"><i class="bi bi-swe-fw bi-swe bi-copy"></i></button>
          <button class="btn btn-swe btn-primary" (click)="deleteSetting(selectedSetting)" [attr.title]="languageService.getItem(1300)"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-trash-fill': !selectedSetting.IsDeleted, 'bi-arrow-counterclockwise': selectedSetting.IsDeleted}"></i></button>
          <button class="btn btn-swe btn-primary" (click)="closeEdit($event)" [attr.title]="languageService.getItem(1301)"><i class="bi bi-swe-fw bi-swe bi-x-lg"></i></button>
        </div>
      </div>
      <swe-templatesettingdata [setting]="selectedSetting" [properties]="properties" *ngIf="selectedSetting" [schedulingTemplate]="template.Type==2"></swe-templatesettingdata>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="(permissionService.permissions.Templates>1)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
  </div>
</div>
