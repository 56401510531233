import { Component, OnChanges, Input, ElementRef, ViewChild, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListService } from '../../_services/list.service';
import { PropertyService } from '../../_services/property.service';
import { relatedObject } from '../../layout/profile.component';



@Component({
  selector: 'swe-abscentdata',
  templateUrl: './abscentdata.component.html'
})
export class AbscentDataComponent implements OnInit, OnChanges, OnDestroy {
  //Id
  @Input() id: number = 0;
  @Input() userid: number = 0;
  @Input() bookingid: number = 0;
  @Input() idList: any[] = [];
  @Input() createNew: boolean = false;
  //Notification
  @Input() notifyEmail: boolean = false;
  @Input() notifySms: boolean = false;
  //Manage Layout
  @Input() buttontext: string = '';
  @Input() visible: boolean;
  @Input() option: any;
  @Output() visibleChange = new EventEmitter<any>();
  //Save
  @Output() doneChange = new EventEmitter<any>();
  
  private _subscriptions: Subscription[] = [];
  private _timereport: any;
  private _loaded: boolean;
  private _loading: boolean;
  private _timetypes: any[] = [];
  private _substitute: boolean = true;
  private _relatedObjectList: relatedObject[] = [];
  constructor(
    public languageService: LanguageService,
    private dataService: DataService,
    private alertService: AlertService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.saveAbscent();
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.visible) {
      this.load();
    }
  }

  public relatedObjectChange() {
    this._relatedObjectList = [];
    this._relatedObjectList.push({ Name: 'StartTimeType', Value: this._timereport.StartTimeType });
    this._relatedObjectList.push({ Name: 'TimeType', Value: this._timereport.TimeType });
    this._relatedObjectList.push({ Name: 'EndTimeType', Value: this._timereport.EndTimeType });
  }
  /*Properties*/
  public get loading() {
    return this._loading;
  }

  public get relatedObjectList() {
    return this._relatedObjectList;
   }
  public get timereport() {
    return this._timereport;
  }
  public get timetypes() {
    return this.getTimeTypes([-1]);
  }
  
  public get substitute() {
    return this._substitute;
  }
  public set substitute(val) {
    this._substitute = val;
  }



  /*Methods*/
  public load() {

    if (this._loaded) { return; }

    if (!this.id) { this.id = 0; }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/timereports/abscent/' + this.id, 'GET')
      .subscribe(res => {
        if (res) {

          if (res.Id == 0) {
            if (!this.permissionService.permissions.EmptyBookingLevel) {
              //Storage
              let storage = localStorage.getItem('level');
              if (!storage) {
                storage = this.permissionService.permissions.BookingLevel;
              }
              if (storage) {
                let storageArr = storage.split('|');
                res.LevelId = storageArr[0];
                if (storageArr.length > 1) {
                  res.LevelName = storageArr[1];
                }
                if (storageArr.length > 2) {
                  res.LevelPath = storageArr[2];
                }
              }
            }
          }

          this._timereport = res;
          this.relatedObjectChange();
          this._loading = false;
          this._loaded = true;
        }
      });
  }
  
  public saveAbscent() {

    if (!this.validatedatetime()) { return; }
    if (!this.validatetimetypes()) { return; }
    if (!this.propertyService.validate(this._timereport.Profile)) { return; }

    let list = this.idList;
    if (!list || list.length == 0) {
      list = [this.id];
    }

    this.synchronouslysave(list, 0);
  }
  public manageVisibility(e) {
    this.visible = e;
    this.visibleChange.emit(this.visible);
  }


  //Functions
  private getTimeTypes(signs: any[]) {

    let list: any[] = [];

    signs.forEach((sign) => {

      if (list.length > 0) {
        list.push({ Name: '-----' });
      }

      this._timetypes.forEach((item) => {
        if (sign == item.Sign) {
          list.push(item);
        }
      });
      
    });

    return list;
  }
  private synchronouslysave(list, counter) {

    let id = list[counter];
    if (!id) {
      return;
    }

    let verb = 'POST';
    let path = '/api/v1/timereports/abscent/';

    let dto: any = {
      Start: this._timereport.Start,
      End: this._timereport.End,
      Profile: this._timereport.Profile,
      Id: this.createNew ? 0 : id,
      BookingId: this.bookingid,
      LevelId: this._timereport.LevelId,
      UserId: this.createNew ? id : this.userid,
      Option: this.option,
      TimeType: this._timereport.TimeType && this._timereport.TimeType != 'undefined' ? this._timereport.TimeType : 0,
      ManualNotifyEmail: this.notifyEmail ? true : false,
      ManualNotifySms: this.notifySms ? true : false,
      Substitute: this._substitute
    };

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });

          counter++;
          if (counter < list.length) {
            this.synchronouslysave(list, counter);
          }
          else {
            this.doneChange.emit(true);

            this.visible = false;
            this.visibleChange.emit(this.visible);
          }
        }
      });
  }
  private validatedatetime() {

    let start = new Date(this._timereport.Start);
    let end = new Date(this._timereport.End);

    /*Manage ShiftTime*/
    if (!this.permissionService.permissions.ShiftTime) {

      start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    }

    /*Test*/
    return this.dateTimeService.validation(start, end);
  }
  private validatetimetypes() {

    //TimeType
    if (this.timetypes.length > 0) {
      if (this._timereport.TimeType == 0) {
        this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
        return false;
      }
      else if (this._timereport.TimeType > 0) {
        let timeTypeObj = this.listService.find(this.timetypes, 'Id', this._timereport.TimeType);
        if (!timeTypeObj) {
          this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
          return false;
        }
      }
    }

    return true;
  }
  private init() {

    this.generalService.timetypes.forEach((timetype) => {

      this._timetypes.push({ Id: timetype.Key, Name: timetype.Value, Sign: timetype.Extra });
      
    });
  }
}
