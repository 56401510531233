import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';
import { DataService } from '../_services/data.service';
import { PropertyService } from '../_services/property.service';
import { PermissionService } from '../_services/permission.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { AlertService } from '../_services/alert.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-messagesend',
  templateUrl: './messagesend.component.html'
})
export class MessageSendComponent  {

  

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private propertyService: PropertyService,
    private permissionService: PermissionService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private location: Location) {

    this.route.paramMap.subscribe(params => {
      this._type = params.get('type');

      if (this._type == 'email') {
        this._typeId = 1;
        this._isEmail = true;
      }
      else if (this._type == 'sms') {
        this._typeId = 3;
        this._isSms = true;
      }
      else if (this._type == 'reply') {
        this._typeId = 6;
        this._isSms = true;
      }

      this._resend = params.get('resend');
      this._id = params.get('id');
      this._serie = params.get('serie');

      this.getBody(params.get('body'));
      this.getSubject(params.get('subject'));
    });



    this.preload();
  }

  private _openTo: boolean;
  private _openBcc: boolean;
  private _openAttachment: boolean;
  private _type: string;
  private _typeId: number;
  private _resend: string;
  private _id: string;
  private _serie: string;
  private _isEmail: boolean;
  private _isSms: boolean;
  private _from: string;
  private _displayname: string;
  private _missing: any[] = [];
  private _disabledTo: boolean = false;
  private _to: string;
  private _cc: string;
  private _bcc: string;
  private _subject: string;
  private _body: string;
  private _files: any[] = [{Content: ''}];
  private _delayed: Date = null;
  private _requestGroups: any[] = [];
  private _requestGroup: number = 0;
  private _users: any[] = [];
  /*Properties*/

  public set requestGroup(val) {
    this._requestGroup = val;
  }
  public get requestGroup() {
    return this._requestGroup;
  }
  public get requestGroups() {
    return this._requestGroups;
  }
  
/*Properties*/
  public get openTo() {
    return this._openTo;
  }
  public get openBcc() {
    return this._openBcc;
  }
  public get openAttachment() {
    return this._openAttachment;
  }
  public get typeId() {
    return this._typeId;
  }
  public get isEmail() {
    return this._isEmail;
  }
  public get isSms() {
    return this._isSms;
  }
  public get from() {
    return this._from;
  }
  public set from(val) {
    this._from = val;
  }
  public get displayname() {
    return this._displayname;
  }
  public set displayname(val) {
    this._displayname = val;
  }
  public get missing() {
    return this._missing;
  }
  public set missing(val) {
    this._missing = val;
  }
  public get disabledTo() {
    return this._disabledTo;
  }
  public get to() {
    return this._to;
  }
  public set to(val) {
    this._to = val;
  }
  public get cc() {
    return this._cc;
  }
  public set cc(val) {
    this._cc = val;
  }
  public get bcc() {
    return this._bcc;
  }
  public set bcc(val) {
    this._bcc = val;
  }
  public get subject() {
    return this._subject;
  }
  public set subject(val) {
    this._subject = val;
  }
  public get body() {
    return this._body;
  }
  public set body(val) {
    this._body = val;
  }
  public get files() {
    return this._files;
  }
  public get delayed() {
    return this._delayed;
  }
  public set delayed(val) {
    this._delayed = val;
  }
  public get smscounter() {
    if (this._body.length > 160) {
      return Math.ceil(this._body.length / 153);
    }
    else {
      return 1;
    }
  }
  public get bccLength() {
    if (this._bcc) {
      return this._bcc.split('\n').length - 1;
    }
    return 0;
  }
  public get ccLength() {
    if (this._cc) {
      return this._cc.split('\n').length - 1;
    }
    return 0;
  }
  public get toLength() {
    if (this._to) {
      return this._to.split('\n').length - 1;
    }
    else {
      return 0;
    }
  }


  /*Methods*/
  public send() {
    if (this._from.length == 0) {
      this.alertService.Add({ message: this.languageService.getItem(985), type: 'danger' });
      return;
    }
    if (this._isEmail && !this.propertyService.validateEmail(this._from, this.languageService.getItem(322))) {
      return;
    }
    if (this._body.length == 0) {
      this.alertService.Add({ message: this.languageService.getItem(837), type: 'danger' });
      return;
    }

    if (this._isSms && this._requestGroup && this._requestGroup > 0) {
      this.requestGroupSend();
      return;
    }

    let to = [];
    this._to.split('\n').forEach((address) => {
      if (address && address.length > 0) {
        address = address.split('(')[0].trim();
        if (this._isEmail) { to.push({ Email: address }) };
        if (this._isSms) { to.push({ Cell: address }) };
      }
    });

    let cc = [];
    this._cc.split('\n').forEach((address) => {
      if (address && address.length > 0) {
        address = address.split('(')[0].trim();
        if (this._isEmail) { cc.push({ Email: address }) };
        if (this._isSms) { cc.push({ Cell: address }) };
      }
    });

    let bcc = [];
    this._bcc.split('\n').forEach((address) => {
      if (address && address.length > 0) {
        address = address.split('(')[0].trim();
        if (this._isEmail) { bcc.push({ Email: address }) };
        if (this._isSms) { bcc.push({ Cell: address }) };
      }
    });

    let attachments = [];
    this._files.forEach((file) => {
      if (file && file.Content) {
        attachments.push(file.Content);
      }
    });

    let dto = {
      Type: this._typeId,
      From: this._from,
      Display: this._displayname,
      Users: to,
      Cc: cc,
      Bcc: bcc,
      Subject: this._subject,
      Body: this._body,
      Attachments: attachments,
      Key: this._id ? this._id : 0
    };

    if (this._delayed) {
      dto["SendTime"] = this._delayed;
    }

    if (this._typeId == 6) {
      dto["Serie"] = this._serie;

      this.dataService.tokenRequest('/api/v1/bookings/' + this._id + '/message', 'POST', dto, 'text')
        .subscribe(res => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.dataService.tokenRequest('/api/v1/messages', 'POST', dto, 'text')
        .subscribe(res => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
  }
  public addAttachment() {
    this._files.push({ Content: '' });
  }
  public deleteAttachment(index) {
    this._files.splice(index, 1);
  }
  public fileHasChanged(file) {
    if (file.Content == null && file.Name) {
      file.Name = '';
    }
  }

  public fromChange(e) {
    if (e == 0) {
      this._requestGroup = -3;
      this._from = "";
      return;
    }
    if (e == -3) {
      this._from = "";
      return;
    }
    this._from = this._requestGroups.find(x => x.Id == e).Name;
  }


  /*Functions*/
  private preload() {

    //General
    this._to = '';
    this._cc = '';
    this._bcc = '';
    this._missing = [];
    this._subject = '';
    this._body = '';

    if (this._resend) {
      this.dataService.tokenRequest('/api/v1/messages/resend/' + this._resend, 'GET', {})
        .subscribe(res => {
          if (res) {
            this._from = res.From;
            this._displayname = res.DisplayName;
            this._subject = res.Subject;
            this._body = res.Body;
            this._to = res.To;
            this._cc = res.CC;
            this._bcc = res.BCC;
            this._openTo = this._to.length > 0;
            this._openBcc = this._bcc.length > 0;

            this._openAttachment = false;
            if (res.Attachments.length > 0) {
              this._openAttachment = true;
              this._files = [];
              res.Attachments.forEach((attachment) => {
                let array = attachment.toString().split('|');
                this._files.push({ Name: array[0], Content: attachment });
              });
            }
          }
        });
    }
    else {
      if (this._isEmail) {
        this._openBcc = true;
        this._from = this.permissionService.user.Email;
        this._displayname = this.permissionService.user.Firstname + ' ' + this.permissionService.user.Lastname;

        let users: any = this.viewCacheService.get('message_users');
        if (users) {
          users.value.forEach((user) => {
            if (user.Email.length > 0) {
              this._bcc += user.Email.replace(/;/g, '\n') + ' (' + user.Firstname + ' ' + user.Lastname + ')\n';
            }
            else {
              this._missing.push({ Id: 0, Name: user.Firstname + ' ' + user.Lastname });
            }
          });
        }

        let levels: any = this.viewCacheService.get('message_levels');
        if (levels) {
          levels.value.forEach((level) => {
            if (level.Email.length > 0) {
              this._bcc += level.Email.replace(/;/g, '\n') + '\n';
            }
            else {
              this._missing.push({ Id: 0, Name: level.Name });
            }
          });
        }
      }
      else if (this._isSms) {
        this._openTo = true;
        this._from = this.permissionService.user.Cell;
        this._displayname = '';

        let users: any = this.viewCacheService.get('message_users');
        if (users) {
          users.value.forEach((user) => {
            if (user.Cell.length > 0) {
              this._to += user.Cell.replace(/;/g, '\n') + ' (' + user.Firstname + ' ' + user.Lastname + ')\n';
              this._users.push(user);
            }
            else {
              let missingLabel = user.Firstname + ' ' + user.Lastname;
              if (user.Username && user.Username.length > 0) {
                missingLabel += ' (' + user.Username + ')'
              }

              this._missing.push({ Id: 0, Name: missingLabel });
            }
          });
          this._requestGroups = [];
          this.dataService.tokenRequest('/api/v1/requests/groups', 'GET', {})
            .subscribe((res) => {
              if (res) {
                this._requestGroups = res;
                if (res.length > 0) {
                  this._requestGroups.unshift({ Id: 0, Name: '---' + this.languageService.getItem(1456) + '---' });
                }
              }
              this._requestGroups.unshift({ Id: -3, Name: this.languageService.getItem(1518) });
              this._requestGroups.unshift({ Id: -2, Name: this.languageService.getItem(316) });
              this._requestGroup = -2;
              this._from = this.languageService.getItem(316);
              if (this.permissionService.user.Cell) {
                this._requestGroups.unshift({ Id: -1, Name: this.permissionService.user.Cell });
                this._requestGroup = -1;
                this._from = this.permissionService.user.Cell;
              }
            });
        }

      }

      if (this._typeId == 6) {
        this._from = this.permissionService.permissions.Reply;
      }

      let disableTo: any = this.viewCacheService.get('message_disableTo');
      if (disableTo) {
        this._disabledTo = disableTo;
      }

      if (this._isSms && (this._from == null || this._from.length == 0)) {
        this._from = this.languageService.getItem(316);
      }
    }
  }

  private requestGroupSend() {
    let dto = {
      Type: 2,
      Users: this._users,
      Header: this._subject,
      Question: this._body,
      RequestGroup: this._requestGroup,
      Booking:  0,
      Serie: 0,
      Options: []
    };

    if (this._delayed) {
      dto["SendTime"] = this._delayed;
    }

    this.dataService.tokenRequest('/api/v1/requests', 'POST', dto, 'text')
      .subscribe(res => {
        this.alertService.Add({ message: res, type: 'success' });
        this.location.back();
      });

  
  }

  private getBody(bodytype) {
    if (bodytype) {
      let query = '';

      //Type
      if (parseInt(bodytype) > 0) {
        query = 'type/' + bodytype + '/';
      }

      //Booking
      if (parseInt(this._id) > 0) {
        query += 'booking/' + this._id ;
      }

      //Serie
      if (parseInt(this._serie) > 0) {
        query += '/serie/' + this._serie;
      }

      //Get
      this.dataService.tokenRequest('/api/v1/messages/body/' + query, 'GET', {}, 'text')
        .subscribe(res => {

          this._body = res.replace(/\r/g, '');
        });
    }
  }
  private getSubject(subjecttype) {
    if (subjecttype) {
      let query = '';

      //Type
      if (parseInt(subjecttype) > 0) {
        query = 'type/' + subjecttype + '/';
      }

      //Booking
      if (parseInt(this._id) > 0) {
        query += 'booking/' + this._id;
      }

      //Get
      this.dataService.tokenRequest('/api/v1/messages/subject/' + query, 'GET', {}, 'text')
        .subscribe(res => {

          this._subject = res.replace(/\r/g, '');
        });
    }
  }
}
